html,
body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Arial", sans-serif;
}

.main {
  width: 100%;
  height: 100%;
  min-height: 550px;
  background-color: #003d43;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  background-image: url("fundopadrao.png");
  background-size: auto;
  background-position: top center;
  background-repeat: repeat;
  background-attachment: fixed;
}

.form {
  width: 70%;
  max-width: 300px;
  background-color: rgba(0,0,0,0.35);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
}

.logo {
  width: 12rem;
  margin-bottom: 3rem;
}

.conta-input {
  outline: 0 !important;
  font-size: 1rem;
  line-height: 1.25;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 270px;

  display: block;
  width: calc(100% - 5rem);
  height: calc(1.3em + 0.5rem + 2px);
  padding: 0.375rem 0.75rem;

  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.conta-input:focus {
  color: #BFD730;
  font-weight: 500;
  background-color: #fff;
  border-color: #BFD730;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(191 215 48 / 25%);
}

.send-btn {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #bfd730;
  border: 1px solid #bfd730;
  padding: 0.5rem 3rem;
  font-size: 01rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.send-btn:hover{
  background-color: #8ba600;
  border-color: #8ba600;
}

.desenv-bludata {
  max-width: 10.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.main span{
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  background-color: rgba(0,0,0,0.35);
  padding: 10px;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}
.main span a{
  color: #bfd730;
  text-decoration: none;
}

.main span a:hover{
  color: #8ba600;
}